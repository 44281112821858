export default {
	filters: {
		numToString(value) {
			return value.toString();
		},
		/**
		 * TODO 获取URL参数
		 * @param {Object} name
		 */
		getQuery(name) {
			const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
			const r = window.location.search.substr(1).match(reg);
			if (r != null) {
				return decodeURIComponent(r[2]);
			}
			return null;
		}
	},
	created() {},
	methods: {}
}
